


























import Vue from "vue";
import Timer from "@/components/Timer.vue";
import Footer from "@/components/navigation-footer.vue";
export default Vue.extend({
  data: function () {
    return {
      values: this.$store.state.values.coreValues,
    };
  },
  components: {
    Timer,
    Footer,
  },
  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
  },
  methods: {
    ToggleValue: function (index: number) {
      if (!this.$store.state.values.coreValues[index].selected) {
        this.$store.dispatch("AddCoreValue", { index: index });
      } else {
        this.$store.dispatch("RemoveCoreValue", { index: index });
      }
    },
  },
});
