import { render, staticRenderFns } from "./3.vue?vue&type=template&id=71586fa3&scoped=true&"
import script from "./3.vue?vue&type=script&lang=ts&"
export * from "./3.vue?vue&type=script&lang=ts&"
import style0 from "./3.vue?vue&type=style&index=0&id=71586fa3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71586fa3",
  null
  
)

export default component.exports